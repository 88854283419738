<template>
    <div class="text-center" v-if="!is_data_ready">
        <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
    <div class="tab-pane fade show active text-center" v-else-if="lineups.length == 0">
        <p>{{ $t("No Lineup data found") }}.</p>
    </div>
    <div v-else class="ftb_lineup_wrapper">
        <div class="ftb_lineup_section">
            <div class="ftb_lineup_block">
                <h2>
                    {{lineups[0].team}}
                </h2>

                <ul>
                    <li v-for="player in lineups[0].players.filter((player)=>player.is_substitute == false)" :key="player.id">
                        <p class="rugby_lineup_wrap">
                            <span class="lineup_bottom_arrow" v-for=" goal in player.goals">
                                <label >
                                    <img v-if="goal.type == 'CON'" :src="require('@/assets/images/CONN.webp')" alt="Goal" class="lineup_image">
                                    <img v-else :src="require('@/assets/images/'+goal.type+'.webp')" alt="Goal" class="lineup_image">
                                    {{goal.minutes}}'
                                </label>
                            </span></p>
                        <p class="rugby_lineup_wrap">
                            {{player.shirt_no}}. {{player.playerName}}
                            <span v-if="player.substitutionsIn.length" v-for="sub in player.substitutionsIn" class="lineup_top_arrow">
                                {{sub.minutes}}'
                                <label>
                                  <img :src="require('@/assets/images/sabc_lineup_in.webp')" alt="sbac_header_top_ad">
                                </label>
                              </span>
                                <span v-if="player.substitutionsOut.length" v-for="sub in player.substitutionsOut" class="lineup_bottom_arrow">
                                    {{sub.minutes}}'
                              <label>
                              <img :src="require('@/assets/images/sabc_lineup_out.webp')" alt="sbac_header_top_ad">
                            </label>
                            </span>
                        </p>

                    </li>
                </ul>
            </div>
            <div class="ftb_lineup_block">
                <h2>
                    {{lineups[1].team}}
                </h2>
                <ul>
                    <li v-for="player in lineups[1].players.filter((player)=>player.is_substitute == false)">
                        <p class="rugby_lineup_wrap">
                            <span class="lineup_bottom_arrow" v-for=" goal in player.goals">
                                <label >
                                    <img v-if="goal.type == 'CON'" :src="require('@/assets/images/CONN.webp')" alt="Goal" class="lineup_image">
                                    <img v-else :src="require('@/assets/images/'+goal.type+'.webp')" alt="Goal" class="lineup_image">
                                    '{{goal.minutes}}
                                </label>
                            </span>
                        </p>
                        <p class="rugby_lineup_wrap">
                            {{player.shirt_no}}. {{player.playerName}}
                            <span v-if="player.substitutionsIn.length" v-for="sub in player.substitutionsIn" class="lineup_top_arrow">
                                '{{sub.minutes}}
                              <label>
                                  <img :src="require('@/assets/images/sabc_lineup_in.webp')" alt="sbac_header_top_ad">
                                </label>
                            </span>
                                <span v-if="player.substitutionsOut.length" v-for="sub in player.substitutionsOut" class="lineup_bottom_arrow">
                                    '{{sub.minutes}}
                              <label>
                              <img :src="require('@/assets/images/sabc_lineup_out.webp')" alt="sbac_header_top_ad">
                            </label>
                            </span>
                        </p>
                    </li>
                </ul>
            </div>
        </div>
        <div class="ftb_lineup_section">
          <div class="ftb_lineup_block">
                <h2>{{$t('football.match-detail.Substitutes')}}</h2>
                <ul>
                  <li v-for="player in lineups[0].players.filter((player)=>player.is_substitute == true)">
                      <p class="rugby_lineup_wrap">
                            <span class="lineup_bottom_arrow" v-for=" goal in player.goals">
                                <label >
                                    <img v-if="goal.type == 'CON'" :src="require('@/assets/images/CONN.webp')" alt="Goal" class="lineup_image">
                                    <img v-else :src="require('@/assets/images/'+goal.type+'.webp')" alt="Goal" class="lineup_image">
                                    {{goal.minutes}}'
                                </label>
                            </span></p>
                      <p class="rugby_lineup_wrap">
                          {{player.shirt_no}}. {{player.playerName}}
                          <span v-if="player.substitutionsIn.length" v-for="sub in player.substitutionsIn" class="lineup_top_arrow">
                                {{sub.minutes}}'
                                <label>
                                  <img :src="require('@/assets/images/sabc_lineup_in.webp')" alt="sbac_header_top_ad">
                                </label>
                              </span>
                          <span v-if="player.substitutionsOut.length" v-for="sub in player.substitutionsOut" class="lineup_bottom_arrow">
                                    {{sub.minutes}}'
                              <label>
                              <img :src="require('@/assets/images/sabc_lineup_out.webp')" alt="sbac_header_top_ad">
                            </label>
                            </span>
                      </p>
                  </li>
                </ul>
            </div>

            <div class="ftb_lineup_block">
                <h2>{{$t('football.match-detail.Substitutes')}}</h2>
                <ul>
                  <li v-for="player in lineups[1].players.filter((player)=>player.is_substitute == true)">
                      <p class="rugby_lineup_wrap">
                            <span class="lineup_bottom_arrow" v-for=" goal in player.goals">
                                <label >
                                    <img v-if="goal.type == 'CON'" :src="require('@/assets/images/CONN.webp')" alt="Goal" class="lineup_image">
                                    <img v-else :src="require('@/assets/images/'+goal.type+'.webp')" alt="Goal" class="lineup_image">
                                    '{{goal.minutes}}
                                </label>
                            </span>
                      </p>
                      <p class="rugby_lineup_wrap">
                          {{player.shirt_no}}. {{player.playerName}}
                          <span v-if="player.substitutionsIn.length" v-for="sub in player.substitutionsIn" class="lineup_top_arrow">
                                '{{sub.minutes}}
                              <label>
                                  <img :src="require('@/assets/images/sabc_lineup_in.webp')" alt="sbac_header_top_ad">
                                </label>
                            </span>
                          <span v-if="player.substitutionsOut.length" v-for="sub in player.substitutionsOut" class="lineup_bottom_arrow">
                                    '{{sub.minutes}}
                              <label>
                              <img :src="require('@/assets/images/sabc_lineup_out.webp')" alt="sbac_header_top_ad">
                            </label>
                            </span>
                      </p>
                  </li>
                </ul>
            </div>
        </div>
    </div>

</template>

<script>
    export default {
        name: "MatchDetailLineup",
        data() {
            return {
                lineups: []
            };
        },
        mounted() {
            this.getMatches();
        },
        serverPrefetch() {
            return this.getMatches();
        },
        metaInfo() {
            let ss = this.$store.state.siteSetting;
            if(this.$store.state.rugbyMatchDetailResponse.data && this.$store.state.rugbyMatchDetailResponse.data.competitors){
                var replacements = {
                    '%HOME_TEAM%': this.$filterTeam(this.$store.state.rugbyMatchDetailResponse.data.competitors, true).name,
                    '%AWAY_TEAM%': this.$filterTeam(this.$store.state.rugbyMatchDetailResponse.data.competitors, false).name,
                    '%DATE%' : this.$route.params.date,
                };
            }

            let title = this.$getSafe(() => this.$replacePlaceHolders(ss.rugby_match_lineup_meta_title,replacements));
            let description = this.$getSafe(() => this.$replacePlaceHolders(ss.rugby_match_lineup_meta_description,replacements));
            let keywords = this.$getSafe(() => ss.rugby_match_lineup_meta_keywords);

            return {
                title: title,
                description: description,
                meta: this.$getMetaTags(title, description, keywords),
                link:[
                    {rel: 'canonical', href: this.$toFullPath(this.$route.fullPath)}],
            };
        },
        methods: {
            getMatches() {
                this.is_data_ready = false;
                return this.$axios
                    .get(this.$RUGBY_MATCH_DETAIL_LINEUPS_API.replace(':match', this.$route.params.matchId))
                    .then(response => {
                        if (response.data) {
                            Object.keys(response.data.lineup).forEach(team => {
                                if (response.data.lineup[team].isHome) {
                                    this.lineups[0] = response.data.lineup[team];
                                } else if(!response.data.lineup[team].isHome) {
                                    this.lineups[1] = response.data.lineup[team];
                                }
                            });
                          this.is_data_ready = true;
                        }
                    })
                    .catch(error => console.log(error))
            },
        }
    };
</script>
